import React from "react";

function Footer() {
  // const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-custom-main-light text-white py-2 text-center mt-10">
      <div className="flex-grow"></div>
      <div className="py-3 flex flex-col justify-center items-center gap-2">
        <p>Sheikh Abu Mujaahid Fareed Abdullah Official Site</p>
        <a
          className="flex justify-center items-center gap-2 hover:underline text-sm"
          href="https://www.youtube.com/@SheikhAbuMujaahidFareedAbdulla"
        >
          <svg
            viewBox="0 0 24 24"
            fill="red"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 hover:brightness-90"
          >
            <path
              d="M23.498 6.186a2.961 2.961 0 0 0-2.081-2.095C19.123 3.5 12 3.5 12 3.5s-7.123 0-9.417.591a2.96 2.96 0 0 0-2.08 2.095C0 8.502 0 12 0 12s0 3.498.503 5.814a2.96 2.96 0 0 0 2.08 2.095c2.294.591 9.417.591 9.417.591s7.123 0 9.417-.591a2.961 2.961 0 0 0 2.081-2.095C24 15.498 24 12 24 12s0-3.498-.502-5.814zM9.75 15.568V8.432L15.75 12l-6 3.568z"
              fill="white"
            />
          </svg>
          Youtube
        </a>
      </div>
    </footer>
  );
}

export default Footer;
